import { default as asesorialegalegSTh0V1OkMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/asesorialegal.vue?macro=true";
import { default as contactenoswFSb5w4oGFMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/contactenos.vue?macro=true";
import { default as democontactjxWb9coTkVMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/democontact.vue?macro=true";
import { default as documentosinteresuooAnTCzrrMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/documentosinteres.vue?macro=true";
import { default as empresaQsZVkfRRXsMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/empresa.vue?macro=true";
import { default as gestiondecobranzas_32copyr6PPd62rJ9Meta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/gestiondecobranzas copy.vue?macro=true";
import { default as gestiondecobranzasns7IKb2ZAGMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/gestiondecobranzas.vue?macro=true";
import { default as indexnyiebf0kT4Meta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/index.vue?macro=true";
import { default as outsourcing_45contable_45quito_45ecuadorC5nFIfOobKMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/outsourcing-contable-quito-ecuador.vue?macro=true";
import { default as component_45stubzhhQnimOtaMeta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubzhhQnimOta } from "C:/Users/sakib/projects/vue/fv-lexcobapp/fv/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: asesorialegalegSTh0V1OkMeta?.name ?? "asesorialegal",
    path: asesorialegalegSTh0V1OkMeta?.path ?? "/asesorialegal",
    meta: asesorialegalegSTh0V1OkMeta || {},
    alias: asesorialegalegSTh0V1OkMeta?.alias || [],
    redirect: asesorialegalegSTh0V1OkMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/asesorialegal.vue").then(m => m.default || m)
  },
  {
    name: contactenoswFSb5w4oGFMeta?.name ?? "contactenos",
    path: contactenoswFSb5w4oGFMeta?.path ?? "/contactenos",
    meta: contactenoswFSb5w4oGFMeta || {},
    alias: contactenoswFSb5w4oGFMeta?.alias || [],
    redirect: contactenoswFSb5w4oGFMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/contactenos.vue").then(m => m.default || m)
  },
  {
    name: democontactjxWb9coTkVMeta?.name ?? "democontact",
    path: democontactjxWb9coTkVMeta?.path ?? "/democontact",
    meta: democontactjxWb9coTkVMeta || {},
    alias: democontactjxWb9coTkVMeta?.alias || [],
    redirect: democontactjxWb9coTkVMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/democontact.vue").then(m => m.default || m)
  },
  {
    name: documentosinteresuooAnTCzrrMeta?.name ?? "documentosinteres",
    path: documentosinteresuooAnTCzrrMeta?.path ?? "/documentosinteres",
    meta: documentosinteresuooAnTCzrrMeta || {},
    alias: documentosinteresuooAnTCzrrMeta?.alias || [],
    redirect: documentosinteresuooAnTCzrrMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/documentosinteres.vue").then(m => m.default || m)
  },
  {
    name: empresaQsZVkfRRXsMeta?.name ?? "empresa",
    path: empresaQsZVkfRRXsMeta?.path ?? "/empresa",
    meta: empresaQsZVkfRRXsMeta || {},
    alias: empresaQsZVkfRRXsMeta?.alias || [],
    redirect: empresaQsZVkfRRXsMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: gestiondecobranzas_32copyr6PPd62rJ9Meta?.name ?? "gestiondecobranzas copy",
    path: gestiondecobranzas_32copyr6PPd62rJ9Meta?.path ?? "/gestiondecobranzas%20copy",
    meta: gestiondecobranzas_32copyr6PPd62rJ9Meta || {},
    alias: gestiondecobranzas_32copyr6PPd62rJ9Meta?.alias || [],
    redirect: gestiondecobranzas_32copyr6PPd62rJ9Meta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/gestiondecobranzas copy.vue").then(m => m.default || m)
  },
  {
    name: gestiondecobranzasns7IKb2ZAGMeta?.name ?? "gestiondecobranzas",
    path: gestiondecobranzasns7IKb2ZAGMeta?.path ?? "/gestiondecobranzas",
    meta: gestiondecobranzasns7IKb2ZAGMeta || {},
    alias: gestiondecobranzasns7IKb2ZAGMeta?.alias || [],
    redirect: gestiondecobranzasns7IKb2ZAGMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/gestiondecobranzas.vue").then(m => m.default || m)
  },
  {
    name: indexnyiebf0kT4Meta?.name ?? "index",
    path: indexnyiebf0kT4Meta?.path ?? "/",
    meta: indexnyiebf0kT4Meta || {},
    alias: indexnyiebf0kT4Meta?.alias || [],
    redirect: indexnyiebf0kT4Meta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/index.vue").then(m => m.default || m)
  },
  {
    name: outsourcing_45contable_45quito_45ecuadorC5nFIfOobKMeta?.name ?? "outsourcing-contable-quito-ecuador",
    path: outsourcing_45contable_45quito_45ecuadorC5nFIfOobKMeta?.path ?? "/outsourcing-contable-quito-ecuador",
    meta: outsourcing_45contable_45quito_45ecuadorC5nFIfOobKMeta || {},
    alias: outsourcing_45contable_45quito_45ecuadorC5nFIfOobKMeta?.alias || [],
    redirect: outsourcing_45contable_45quito_45ecuadorC5nFIfOobKMeta?.redirect,
    component: () => import("C:/Users/sakib/projects/vue/fv-lexcobapp/fv/pages/outsourcing-contable-quito-ecuador.vue").then(m => m.default || m)
  },
  {
    name: component_45stubzhhQnimOtaMeta?.name ?? undefined,
    path: component_45stubzhhQnimOtaMeta?.path ?? "/pages/nosotros",
    meta: component_45stubzhhQnimOtaMeta || {},
    alias: component_45stubzhhQnimOtaMeta?.alias || [],
    redirect: component_45stubzhhQnimOtaMeta?.redirect,
    component: component_45stubzhhQnimOta
  }
]